import ContentManagerLayout from 'src/core/contentManager/components/templates/ContentManagerLayout';
import ThemeProvider from 'src/core/theme/context';
import { ContentManagerBanner } from 'src/core/contentManager/components/organisms/ContentManagerBanner';
import { useDefaultContentManagerBanner } from 'src/core/contentManager/hooks';
import DefaultContentManagerBannerText from 'src/core/contentManager/components/atoms/DefaultContentManagerBannerText';
import { CategoryPageProps } from 'src/psychics-categories/entities';
import CategorySections from '../components/CategorySections';
import styles from './CategoryPage.module.scss';

const CategoryPage = ({ metaData, sections, slug, header, footer }: CategoryPageProps) => {
  const { getBannerProps } = useDefaultContentManagerBanner();

  const renderBannerText = () => <DefaultContentManagerBannerText />;

  return (
    <ThemeProvider>
      <div className={styles.root}>
        <ContentManagerLayout
          metaData={metaData}
          header={header}
          footer={footer}
          banner={<ContentManagerBanner {...getBannerProps()} renderText={renderBannerText} />}
        >
          <CategorySections sections={sections} slug={slug} />
        </ContentManagerLayout>
      </div>
    </ThemeProvider>
  );
};

export default CategoryPage;

import cn from 'classnames';
import Link from 'src/core/common/components/common/Link';
import { TopicSection } from 'src/core/contentManager/entities';
import Image from 'src/core/common/components/common/Image';
import styles from './SectionTopics.module.scss';

export type SectionTopicsProps = {
  className?: string;
  sectionContent: TopicSection;
};

const SectionTopics = ({ className, sectionContent }: SectionTopicsProps) => {
  const { topics } = sectionContent;
  return (
    <section className={cn(styles.root, className)} id={sectionContent.component}>
      <div className={styles.content}>
        <h2 className={styles.title}>{sectionContent.title}</h2>
        <ul className={styles.topicList}>
          {topics.map(({ title, icon, id, link }) => (
            <li key={id} className={styles.topicListItem}>
              <div className={styles.topic}>
                {link && (
                  <Link
                    href={link.url}
                    className={styles.link}
                    aria-label={`Go to ${title} category page`}
                  />
                )}
                <Image
                  type="future"
                  width={icon.width}
                  height={icon.height}
                  className={styles.topicImage}
                  src={icon.url}
                  alt={icon.alt}
                />
                <span className={styles.topicText}>{title}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SectionTopics;

import cn from 'classnames';
import { StartSection } from 'src/psychics-categories/entities';
import Image from 'src/core/common/components/common/Image';
import styles from './SectionStart.module.scss';

export type SectionStartProps = {
  className?: string;
  sectionContent: StartSection;
};
const SectionStart = ({ className, sectionContent }: SectionStartProps) => {
  const { title, description, imageDesktop, imageMobile } = sectionContent;

  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.textColumn}>
            <div className={styles.textContent}>
              <h1 className={styles.title}>{title}</h1>
              <p className={styles.description}>{description}</p>
            </div>
          </div>
          <div className={styles.imageColumn}>
            <Image
              type="future"
              src={imageMobile.url}
              alt={imageMobile.alt}
              width={imageMobile.width}
              height={imageMobile.height}
              className={styles.imageMobile}
            />
            <Image
              type="future"
              src={imageDesktop.url}
              alt={imageDesktop.alt}
              width={imageDesktop.width}
              height={imageDesktop.height}
              className={styles.imageDesktop}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionStart;

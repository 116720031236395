import dynamic from 'next/dynamic';
import { FlowName } from 'src/core/quiz/entities';
import { useCategories } from 'src/psychics-categories/hooks/useCategories';
import SectionApplicationAdvantages from 'src/core/contentManager/components/sections/SectionApplicationAdvantages';
import SectionFaq from 'src/core/contentManager/components/sections/SectionFaq';
import SectionProblems from 'src/core/contentManager/components/sections/SectionProblems';
import SectionSeoText from 'src/core/contentManager/components/sections/SectionSeoText';
import SectionTopics from 'src/core/contentManager/components/sections/SectionTopics';
import SectionWhy from 'src/core/contentManager/components/sections/SectionWhy';
import { ContentManagerSectionsType } from 'src/core/contentManager/entities';
import SectionVideo from 'src/core/contentManager/components/organisms/SectionVideo';
import { getSectionKey } from 'src/core/contentManager/utils/getSectionKey';
import { CategoryPageProps, CategoryPageSectionType } from '../../entities';
import SectionExperts from '../sections/SectionExperts';
import SectionStart from '../sections/SectionStart';
import styles from './CategorySections.module.scss';

type CategorySectionsProps = Pick<CategoryPageProps, 'sections' | 'slug'>;

const LazyExpertsCatalogueModal = dynamic(
  () => import('src/core/common/components/common/ExpertsCatalogueModal'),
  {
    ssr: false,
  },
);

const CategorySections = ({ sections, slug }: CategorySectionsProps) => {
  const {
    experts,
    startChat,
    seeMoreExperts,
    selectExpert,
    findExpert,
    modalOpened,
    selectedExpertId,
    closeModal,
  } = useCategories({ slug });

  return (
    <>
      {sections.map((section) => {
        switch (section.component) {
          case CategoryPageSectionType.START_SECTION:
            return (
              <SectionStart
                className={styles.section}
                key={getSectionKey(section)}
                sectionContent={section}
              />
            );
          case ContentManagerSectionsType.VIDEO_SECTION:
            return (
              <SectionVideo
                key={getSectionKey(section)}
                sectionContent={section}
                className={styles.section}
              />
            );
          case CategoryPageSectionType.EXPERTS_SECTION:
            return (
              <SectionExperts
                className={styles.section}
                key={`${CategoryPageSectionType.EXPERTS_SECTION}-${section.id}`}
                sectionContent={section}
                experts={experts}
                startChat={startChat}
                seeMoreExperts={seeMoreExperts}
                selectExpert={selectExpert}
              />
            );
          case ContentManagerSectionsType.TOPIC_SECTION:
            return (
              <SectionTopics
                className={styles.section}
                sectionContent={section}
                key={`${ContentManagerSectionsType.TOPIC_SECTION}-${section.id}`}
              />
            );
          case ContentManagerSectionsType.WHY_SECTION:
            return (
              <SectionWhy
                sectionContent={section}
                className={styles.section}
                key={`${ContentManagerSectionsType.WHY_SECTION}-${section.id}`}
                findExpert={findExpert}
              />
            );
          case ContentManagerSectionsType.PROBLEMS_SECTION:
            return (
              <SectionProblems
                sectionContent={section}
                className={styles.section}
                key={`${ContentManagerSectionsType.PROBLEMS_SECTION}-${section.id}`}
              />
            );
          case ContentManagerSectionsType.APPLICATION_ADVANTAGES_SECTION:
            return (
              <SectionApplicationAdvantages
                sectionContent={section}
                className={styles.section}
                key={`${ContentManagerSectionsType.APPLICATION_ADVANTAGES_SECTION}-${section.id}`}
              />
            );
          case ContentManagerSectionsType.SEO_TEXT_SECTION:
            return (
              <SectionSeoText
                key={`${ContentManagerSectionsType.SEO_TEXT_SECTION}-${section.id}`}
                className={styles.section}
                sectionContent={section}
              />
            );
          case ContentManagerSectionsType.FAQ_SECTION:
            return (
              <SectionFaq
                sectionContent={section}
                className={styles.section}
                key={`${ContentManagerSectionsType.FAQ_SECTION}-${section.id}`}
              />
            );
          default:
            return null;
        }
      })}
      {modalOpened && (
        <LazyExpertsCatalogueModal
          onClose={closeModal}
          flowName={FlowName.H0ME}
          selectedExpertId={selectedExpertId}
          isOpen={modalOpened}
        />
      )}
    </>
  );
};

export default CategorySections;

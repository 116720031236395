import cn from 'classnames';
import Button from 'src/core/common/components/common/Button';
import { Expert, ExpertId } from 'src/core/experts/entities';
import ExpertCard from 'src/core/contentManager/components/ExpertCard';
import { ExpertsSection } from 'src/psychics-categories/entities';
import styles from './SectionExperts.module.scss';

export type SectionExpertsProps = {
  className?: string;
  sectionContent: ExpertsSection;
  experts: Array<Expert>;
  startChat: (id: ExpertId) => void;
  selectExpert: (id: ExpertId) => void;
  seeMoreExperts: () => void;
};

const SectionExperts = ({
  className,
  sectionContent,
  experts,
  selectExpert,
  seeMoreExperts,
  startChat,
}: SectionExpertsProps) => {
  const { button } = sectionContent;

  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.content}>
        <div className={styles.expertListWrapper}>
          <ul className={styles.expertList}>
            {experts.map((expert) => (
              <li key={expert.id} className={styles.expertListItem}>
                <ExpertCard
                  className={styles.expert}
                  onStartChat={() => startChat(expert.id)}
                  onExpertClick={() => selectExpert(expert.id)}
                  info={expert}
                />
              </li>
            ))}
          </ul>
        </div>
        <Button
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          onClick={seeMoreExperts}
        >
          {button.text}
        </Button>
      </div>
    </section>
  );
};

export default SectionExperts;

import cn from 'classnames';
import Button from 'src/core/common/components/common/Button';
import Image from 'src/core/common/components/common/Image';
import { WhySection } from 'src/core/contentManager/entities';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './SectionWhy.module.scss';

export type SectionWhyProps = {
  className?: string;
  sectionContent: WhySection;
  findExpert: () => void;
};

const SectionWhy = ({ className, sectionContent, findExpert }: SectionWhyProps) => {
  const { title, items, button } = sectionContent;

  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <ul className={styles.items}>
          {items.map(({ id, title: itemTitle, description, icon }) => (
            <li key={id} className={styles.item}>
              <div className={styles.itemInner}>
                <div className={styles.itemIconWrapper}>
                  <Image
                    type="future"
                    className={styles.itemIcon}
                    src={icon.url}
                    alt={icon.alt}
                    sizes="100%"
                    fill
                  />
                </div>
                <p className={styles.itemTitle}>{itemTitle}</p>
                <p className={styles.itemDescription}>{description}</p>
              </div>
            </li>
          ))}
        </ul>
        <Button
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          afterIcon={<Icon name="arrow" className={styles.icon} />}
          labelClassName={styles.buttonLabel}
          onClick={findExpert}
        >
          {button.text}
        </Button>
      </div>
    </section>
  );
};

export default SectionWhy;

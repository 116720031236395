import cn from 'classnames';
import { ApplicationAdvantagesSection } from 'src/core/contentManager/entities';
import Image from 'src/core/common/components/common/Image';
import Button from 'src/core/common/components/common/Button';
import { prepareHtml } from 'src/core/common/utils/prepareHtml';
import Icon from 'src/core/svg-sprites/ui/atoms/Icon';
import styles from './SectionApplicationAdvantages.module.scss';

export type SectionApplicationAdvantagesProps = {
  className?: string;
  sectionContent: ApplicationAdvantagesSection;
  onClick?: () => void;
};

const SectionApplicationAdvantages = ({
  className,
  sectionContent,
  onClick,
}: SectionApplicationAdvantagesProps) => {
  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.content}>
        <div className={styles.description}>
          <h2 className={styles.title}>{sectionContent.title}</h2>
          <ul className={styles.featuresList}>
            {sectionContent.advantages.map(({ id, text }) => (
              <li key={id} className={styles.featureItem}>
                <Icon name="star-seven-points" className={styles.icon} />
                <div dangerouslySetInnerHTML={prepareHtml(text)}></div>
              </li>
            ))}
          </ul>

          {sectionContent.button && (
            <Button
              wrapperClassName={styles.buttonWrapper}
              afterIcon={<Icon name="arrow" className={styles.buttonIcon} />}
              className={styles.button}
              iconClass={styles.buttonIcon}
              labelClassName={styles.buttonLabel}
              onClick={onClick}
            >
              {sectionContent.button}
            </Button>
          )}
        </div>
        <div className={styles.imageContainer}>
          <Image
            type="future"
            className={cn(styles.image, styles.imageDesktop)}
            src={sectionContent.imageDesktop.url}
            width={sectionContent.imageDesktop.width}
            height={sectionContent.imageDesktop.height}
            alt={sectionContent.imageDesktop.alt}
          />
          <Image
            type="future"
            className={cn(styles.image, styles.imageMobile)}
            src={sectionContent.imageMobile.url}
            width={sectionContent.imageMobile.width}
            height={sectionContent.imageMobile.height}
            alt={sectionContent.imageMobile.alt}
          />
        </div>
      </div>
    </section>
  );
};

export default SectionApplicationAdvantages;

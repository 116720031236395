import cn from 'classnames';
import { ProblemsSection } from 'src/core/contentManager/entities';
import styles from './SectionProblems.module.scss';

export type SectionProblemsProps = {
  className?: string;
  sectionContent: ProblemsSection;
};

const SectionProblems = ({ className, sectionContent }: SectionProblemsProps) => {
  const { title, items } = sectionContent;

  return (
    <section className={cn(styles.root, className)}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        <ul className={styles.items}>
          {items.map(({ id, title: itemTitle, description }) => (
            <li key={id} className={styles.item}>
              <p className={styles.itemTitle}>{itemTitle}</p>
              <p className={styles.itemDescription}>{description}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default SectionProblems;

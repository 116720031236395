import cn from 'classnames';
import Button from 'src/core/common/components/common/Button';
import ClampLines from 'src/core/common/components/layout/ClampLines';
import { SeoTextSection } from 'src/core/contentManager/entities';
import { prepareHtml } from 'src/core/common/utils/prepareHtml';
import styles from './SectionSeoText.module.scss';

type SectionSeoTextProps = {
  className?: string;
  sectionContent: SeoTextSection;
};

const SectionSeoText = ({ className = '', sectionContent }: SectionSeoTextProps) => {
  const { hasBackground, text, title } = sectionContent;

  const rootClasses = cn(
    { [styles.root]: true },
    { [styles.hasBackground]: hasBackground },
    { [className]: !!className },
  );

  return (
    <section className={rootClasses}>
      <div className={styles.content}>
        {title && <h2 className={styles.title}>{title}</h2>}
        <ClampLines
          renderButton={({ isClamped, toggleClamp }) => (
            <Button
              wrapperClassName={styles.buttonWrapper}
              className={styles.button}
              onClick={toggleClamp}
            >
              Show {isClamped ? 'more' : 'less'}
            </Button>
          )}
        >
          <div className={styles.text} dangerouslySetInnerHTML={prepareHtml(text)} />
        </ClampLines>
      </div>
    </section>
  );
};

export default SectionSeoText;

import { useEffect } from 'react';
import { useExpertsCatalogueModalState, useRouter, useServices } from 'src/core/common/hooks';
import { useExperts } from 'src/core/experts/store/queries';
import { ExpertId } from 'src/core/experts/entities';
import { BASE_ROUTES } from 'src/core/common/constants/routes';
import { CATEGORY_PAGE_GET_EXPERTS_OPTIONS } from 'src/psychics-categories/constants';
import { useLastSeenModalContext } from 'src/core/contentManager/hooks';
import {
  CatalogueCategoryBrowseAdvisorsClickEvent,
  CatalogueCategoryExpertCardClickEvent,
  CatalogueCategoryExpertCardStartChatClickEvent,
  CatalogueCategoryFindAdvisorClickEvent,
  CatalogueCategoryOpenEvent,
} from '../analytics/events';

type UseCategoriesParams = {
  initialExpertsAmount?: number;
  slug: string;
};

export function useCategories({ slug }: UseCategoriesParams) {
  const { cancel: cancelLastSeenModal } = useLastSeenModalContext();
  const router = useRouter();
  const { experts } = useExperts({
    ...CATEGORY_PAGE_GET_EXPERTS_OPTIONS,
  });

  const { modalOpened, selectedExpertId, closeModal, openModal } = useExpertsCatalogueModalState();

  const { analyticsService } = useServices();

  const selectExpert = (id: ExpertId) => {
    cancelLastSeenModal();
    analyticsService.track(new CatalogueCategoryExpertCardClickEvent({ landing: slug }));
    openModal(id);
  };

  const startChat = (id: ExpertId) => {
    cancelLastSeenModal();
    analyticsService.track(new CatalogueCategoryExpertCardStartChatClickEvent({ landing: slug }));
    openModal(id);
  };

  const seeMoreExperts = () => {
    analyticsService.track(new CatalogueCategoryBrowseAdvisorsClickEvent({ landing: slug }));

    router.push(BASE_ROUTES.SIMPLE_SIMPLE_REGISTRATION_QUIZ_GENDER);
  };

  const findExpert = () => {
    analyticsService.track(new CatalogueCategoryFindAdvisorClickEvent({ landing: slug }));

    router.push(BASE_ROUTES.SIMPLE_SIMPLE_REGISTRATION_QUIZ_GENDER);
  };

  useEffect(() => {
    analyticsService.track(new CatalogueCategoryOpenEvent({ landing: slug }));
  }, [analyticsService, slug]);

  return {
    selectExpert,
    startChat,
    seeMoreExperts,
    findExpert,
    experts,
    closeModal,
    modalOpened,
    selectedExpertId,
  };
}

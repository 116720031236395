import cn from 'classnames';
import { ReactNode, useCallback, useState } from 'react';
import { mapDomElementsWithModification } from 'src/core/common/utils/mapDomElementsWithModification';
import styles from './ClampLines.module.scss';

type ClampLinesProps = {
  children: ReactNode;
  renderButton: (data: { isClamped: boolean; toggleClamp: () => void }) => ReactNode;
};

/* TODO: Use css-in-js to pass line-height and amount of lines to show from props */
const ClampLines = ({ children, renderButton }: ClampLinesProps) => {
  const [isClamped, setIsClamped] = useState(true);
  const toggleClamp = useCallback(() => setIsClamped((prev) => !prev), []);

  const modifiedChildren = mapDomElementsWithModification(children, {
    className: cn({ [styles.clampedText]: isClamped }),
  });

  return (
    <>
      {modifiedChildren}
      {renderButton({
        isClamped,
        toggleClamp,
      })}
    </>
  );
};

export default ClampLines;
